import 'bootstrap/dist/css/bootstrap.css' 
import { Routes, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { FormattedMessage } from "react-intl"
import CookieConsent from "react-cookie-consent"

import NavSidebar from './NavSidebar'
import NavBottomBar from './NavBottomBar'
import Title from './Title'
import About from './content/About'
import Use from './content/Use'
import Jobs from './content/Jobs'
import Contact from './content/Contact'
import Blog from './content/Blog'
import Imprint from './content/Imprint'
import Privacy from './content/Privacy'
import ScrollButton from './ScrollToTopButton'

import '../css/App.css'

function App() {
  const location = useLocation()

  return (
    <div className="App">
      <Routes location={location} key={location.pathname}>
        <Route path='/' element={ < Title /> } /> 
      </Routes>
      <NavSidebar />
      <AnimatePresence>
        <div id="content" className='content'>
          <Routes location={location} key={location.pathname}>
            <Route path='/about' element={ < About /> } />
            <Route path='/use' element={ < Use /> } />
            <Route path='/jobs' element={ < Jobs /> } />
            <Route path='/contact' element={ < Contact /> } />
            <Route path='/blog' element={ < Blog /> } />
            <Route path='/imprint' element={ < Imprint /> } />
            <Route path='/privacy' element={ < Privacy /> } />
          </Routes>
          <CookieConsent 
            buttonText={<FormattedMessage id='cookieConsentButton'/>}
            style={{ backgroundColor: "white", color: '#333', borderTop: '2px solid #333'}}
            buttonStyle={{ backgroundColor: "#0071bb", fontSize: "13px", color: "#fff"}}
            ><FormattedMessage id='cookieConsent'/></CookieConsent>
        </div>
      </AnimatePresence>
      <ScrollButton />
      <NavBottomBar />
    </div>
  )
}

export default App
