import React, { useState } from "react"

import '../css/App.css'

const ScrollButton = () => {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 250) {
            setVisible(true)
        } else if (scrolled <= 250) {
            setVisible(false)
        }
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    window.addEventListener("scroll", toggleVisible)

    return (
        <div className="scrollButton" onClick={scrollToTop} style={{ display: visible ? "inline" : "none" }} />
    );
};

export default ScrollButton